html {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 100vh;
}

@font-face {
  font-family: "CustomFont";
  src: url("../public/font/SweiSpringCJKtc-Black.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "CustomFont", sans-serif;
}

#container {
  position: relative;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}

.video-component {
  position: absolute;
  top: 0;
  left: 25vw;
  width: 50vw;
  height: 27vh;
  overflow: hidden;
  padding: 3%;
  background-image: url("../public/img/video/video-call-bg.png");
  background-repeat: no-repeat;
  background-size: cover; /* Optional: Adjusts the size of the background image to cover the entire element */
  background-position: center;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.video-component h3 {
  color: white;
  margin-top: 0;
  margin-bottom: 0;
}

.folded-video-component {
  position: absolute;
  top: 0;
  left: 16.7vw;
  width: 11vw;
  height: 22vh;
  overflow: hidden;
  padding: 10%;
  background-color: rgba(0, 0, 0, 0.5);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.folded-video-component h4 {
  color: white;
  margin-top: 0;
  margin-bottom: 8%;
}

.fold-video {
  color: white;
  cursor: pointer;
}

.video-container {
  height: 15vh;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  margin-top: 2%;
  margin-bottom: 2%;
  display: flex;
  position: relative;
}

.videos {
  display: inline-block;
  margin: 4px;
  flex: 0 0 25%;
}

.video-user-info {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5); /* #F53838 with 50% transparency */
  padding-bottom: 2%;
  padding-left: 2%;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}

.video-user-info img {
  margin-right: 2%;
  height: 90%;
  padding: 1%;
}

.user-name {
  color: white;
  height: 100%;
  padding: 1%;
  display: inline-flex;
  flex-shrink: 1;
  white-space: nowrap;
  overflow: hidden;
}

.agora-button-control-container {
  display: flex;
  justify-content: space-between; /* This will push the left and right divs to the ends */
  width: 100%; /* Optional: Ensure the container takes the full width */
}

.agora-button-control-left {
  display: flex; /* Use flexbox for the left container */
}

.agora-button-control-right {
  display: flex; /* Use flexbox for the right container */
}

.button-general {
  background-color: transparent;
  border: none;
  position: relative;
  white-space: nowrap;
  font-family: "CustomFont", sans-serif;
}

.button-secondary-general {
  background-image: transparent;
  border: none;
  position: relative;
  white-space: nowrap;
}

.button-text {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -55%);
  color: white; /* Set the color of your text */
  font-size: 100%; /* Set the font size of your text */
}

.button-secondary-text {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -55%);
  color: #0065e7; /* Set the color of your text */
  font-size: 16px; /* Set the font size of your text */
}

.loading-page {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.loading-background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.progress-bar {
  height: 2.4vh;
  background: linear-gradient(101deg, #2590f7 0%, #b428ff 100%);
  border-radius: 0 1.2vh 1.2vh 0;
  opacity: 1;
  position: absolute;
  bottom: 0;
  left: 0;
}

.percentage {
  position: absolute;
  bottom: 0;
  right: 1%;
  transform: translateX(-50%);
  color: white; /* Change color as needed */
}

.recorder-button-container {
  position: fixed;
  bottom: 4vh;
  right: 9vw;
  z-index: 2;
  display: flex;
  flex-direction: row-reverse;
  align-items: center; /* Vertically center the child elements */
  -webkit-touch-callout: none !important;
}

.status {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  position: relative;
  padding: 10%;
  white-space: nowrap;
}

.recording-button-group {
  color: white;
  font-size: 1.25vw;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Horizontally center the child element */
  align-items: center;
}

.recording-button {
  background: transparent; /* Set the background to transparent */
  border: none; /* Remove border for a clean look */
  cursor: pointer; /* Add cursor pointer for better user experience */
}

.popup-general {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.popup-content {
  width: 20vw;
  background-color: white; /* Set your desired background color */
  padding: 1.5% 1.5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 2vh;
}

.popup-text-content {
  text-align: justify;
  margin-bottom: 1vh; /* Add margin to separate text from buttons */
  font-size: 1.5vh;
}

.popup-button-container {
  display: flex;
  justify-content: space-between;
  width: 70%; /* Ensure the button container spans the entire width */
  min-height: 4vh; /* Set a minimum height for the button container */
  align-items: stretch;
}

.popup-button-general,
.popup-secondary-button-general {
  width: 50%; /* Adjust as needed */
  background: none;
  border: none;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  margin: 0 5px;
}

.popup-button-image,
.popup-secondary-button-image {
  width: 100%;
  height: 90%;
  display: block; /* Change display to block */
}

.popup-button-image {
  background: linear-gradient(101deg, #2590f7 0%, #b428ff 100%);
  background-position: center;
  border-radius: 4vh;
}

.popup-secondary-button-image {
  background-color: white;
  background-position: center;
  border-radius: 4vh;
  border: 1px solid #0065e7;
}

.popup-button-text,
.popup-secondary-button-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  font-size: 1.5vh;
  font-family: "CustomFont", sans-serif;
}

.popup-button-text {
  color: white;
}

.popup-secondary-button-text {
  color: #0065e7;
}

/* 电脑 */
@media (min-width: 933px) {
  .folded-video-component h4 {
    font-size: 22px;
  }
  .video-container {
    margin-top: 3%;
    margin-bottom: 3%;
  }
  .user-name {
    font-size: 100%;
  }
}

/* 手机 */
@media (max-width: 932px) {
  .folded-video-component h4 {
    font-size: 1.5vw;
  }
  .video-container {
    margin-top: 2%;
    margin-bottom: 2%;
  }
  .video-component h3 {
    font-size: 1.7vw;
  }
  .fold-video {
    font-size: 1.5vw;
  }
  .user-name {
    font-size: 80%;
  }
  .popup-content {
    padding: 1%;
  }
  .progress-bar {
    height: 4vh;
  }
  .popup-content {
    width: 40vw;
    padding: 1.5% 1.5%;
  }
  .popup-button-container {
    min-height: 8vh; /* Set a minimum height for the button container */
  }
  .popup-text-content {
    font-size: 3vh;
  }
  .popup-button-text,
  .popup-secondary-button-text {
    font-size: 3vh;
  }
}
/* 
#container { display:block; }
@media only screen and (orientation:portrait){
  #container {   
    height: 100vw;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}
@media only screen and (orientation:landscape){
  #container {  
     -webkit-transform: rotate(0deg);
     -moz-transform: rotate(0deg);
     -o-transform: rotate(0deg);
     -ms-transform: rotate(0deg);
     transform: rotate(0deg);
  }
} */
#canvas-wrap input {
  /* 强制设置高度为 1px */
  height: 1px !important;
}
